<template>
  <v-content>
    <v-row>
      <v-col
        cols="12"
        md="8"
      >
        <h2>Impresión de Credencial</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="6"
        md="6"
      >
        <v-row>
          <v-col
            cols="12"
            md="8"
          >
            <p>EMPLEADO:  <span>{{ this.employee }}</span></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="8"
          >
            <p>DNI:  <span>{{ this.dni }}</span></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="8"
          >
            <p>
              ROL: <span>{{ this.rol }}</span>
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="6"
        md="6"
      >
        <VueQr
          id="qrview"
          :bg-src="src2"
          :text="dni"
          :size="200"
          :dot-scale="0.7"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-spacer></v-spacer>
        <v-btn
          color="warning"
          @click="generatePDF()"
        >
          <v-icon>
            {{ icons.mdiPrinter }}
          </v-icon> Imprimir
        </v-btn>
      </v-col>
    </v-row>

    <div hidden>
      <VueQr
        id="qr"
        :bg-src="src2"
        :text="dni"
        :size="300"
        :dot-scale="0.7"
      />
    </div>
  </v-content>
</template>
<script>
import jsPDF from 'jspdf'
import VueQr from 'vue-qr'
import { mdiPrinter, mdiAccountSearchOutline } from '@mdi/js'
import src2 from '../../assets/images/credential/logo.png'

export default {
  icons: {
    mdiAccountSearchOutline,
    mdiPrinter,

  },
  components: {
    VueQr,
  },
  data() {
    return {
      datos: true,
      src2,
      employee: '',
      dni: '',
      rol: '',
      icons: {
        mdiPrinter,
        mdiAccountSearchOutline,
      },
      Validaciones: {
        requerido: v => !!v || 'Campo Requerido',
      },
    }
  },
  mounted() {

    location.reload
    

    this.cargarDatos()
   
  },
  methods: {
    async  cargarDatos() {
      this.dni = `${await this.$route.params.dni}`
      this.rol = await this.$route.params.rol
      this.employee = await this.$route.params.employee
      this.$forceUpdate()
    },
    generatePDF() {
      //   const doc = new jsPDF({
      //     orientation: "portrait",
      //     unit: "px",
      //     //format: [4, 2],
      //     format: "A4",
      //     margin: { top: 20 },
      //   });

      const doc = new jsPDF('p', 'pt')

      const credential = require('../../assets/images/credential/credential1.png')

      const imgfondo = new Image()
      imgfondo.src = credential
      doc.addImage(imgfondo, 'PNG', 40, 40, 229, 350)

      this.sizeqr = 300
      doc.addImage(document.getElementById('qr'), 110, 190, 100, 100)

      //   doc.addImage(document.getElementById('qr'), 110, 200, 100, 100)

      //   doc.setFillColor(79, 107, 26)
      //   doc.rect(50, 60, 300, 10, 'F')
      doc.rect(40, 40, 229, 350)

      //   doc.setFillColor(79, 107, 26)
      //   doc.rect(80, 210, 300, 10, 'F')

      // doc.addImage(document.getElementById('qr'), 0, 0, 80, 80);

      const empleado = ` Colaborador: ${this.employee}`
      const cargo = ` Cargo: ${this.rol}`
      const dni = ` ID: ${this.dni}`

      doc.setFontSize(9)
      doc.text(empleado, doc.internal.pageSize.width * 0.08, 320, {
        aling: 'right',
      })
      doc.setFillColor(220, 71, 31)
      doc.circle(doc.internal.pageSize.width * 0.078, 316, 2, 'F')
      doc.text(
        cargo,
        doc.internal.pageSize.width * 0.08,
        350,
        {
          aling: 'right',
        },
      )
      doc.setFillColor(220, 71, 31)
      doc.circle(doc.internal.pageSize.width * 0.078, 346, 2, 'F')
      doc.text(
        dni,
        doc.internal.pageSize.width * 0.08,
        380,
        {
          aling: 'right',
        },
      )
      doc.setFillColor(220, 71, 31)
      doc.circle(doc.internal.pageSize.width * 0.078, 376, 2, 'F')

      doc.save('credencial - '+dni+'.pdf', false)
    
      this.$router.push({ name: 'dashboard' })
      storage.clear();
      // doc.output('dataurlnewwindow')
    },

  },
}
</script>
